<template>
  <b-card header="Add Notification">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(submitNotification)">

        <common-notification-fields :common-notification.sync="commonNotification" />

        <relation-type-checkbox
          rules="required"
          :relation-type.sync="entityNotification.relation_type"
        />

        <b-row>
          <b-col>
            <back />
          </b-col>
          <b-col class="text-right">
            <submit-button
              v-bind="{
                submit:sendNotificationToEntityUsers,
                handleSubmit}"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import CommonNotificationFields from '@/common/components/Notifications/CommonNotificationFields.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import RelationTypeCheckbox from '@/common/components/Notifications/RelationTypeCheckbox.vue'

export default {
  name: 'AddNotification',
  components: {
    Back,
    CommonNotificationFields,
    SubmitButton,
    RelationTypeCheckbox,
  },
  data() {
    return {
      targetedAudience: 'All Users',
      commonNotification: {
        title: '',
        body: '',
        sendsAt: this.$moment().format('YYYY-MM-DD HH:mm'),
        sendsImmediately: 0,
      },
      entityNotification: {
        entity: this.$store.getters['mainEntity/getEntityId'],
        relation_type: [],
      },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert, getValidationState,
    }
  },
  methods: {
    sendNotificationToEntityUsers() {
      return this.$portalUsers.post('/internalops/notifications/send/entity', {
        ...this.commonNotification,
        entity: this.entityNotification.entity,
        relation_type: this.parseRelationType(),
      }).then(() => {
        this.successfulOperationAlert('Notification is added successfully')
        this.$router.push({ name: 'organizations-notifications-list' })
      })
    },

    parseRelationType() {
      return this.entityNotification.relation_type.length === 1 ? this.entityNotification.relation_type[0] : 'all'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
